import { MarketingCardWithRow } from "@bluebottlecoffee/design-system/components";
import { toHTML } from "@portabletext/to-html";
import { ImageWithAltText, MarketingCard } from "../sanity-schema";
import { toImageProps } from "./image";
import { toLinkProps } from "./link";

type MarketingCardWithPlacementProps = {
  marketingCard: MarketingCard;
  columnSpan: number;
  rowPlacement: number;
};
type MarketingCardTransformerProps = {
  data: MarketingCardWithPlacementProps;
  lang: string;
  region: string;
};

export function toMarketingCardProps({
  data,
  lang,
  region,
}: MarketingCardTransformerProps): MarketingCardWithRow {
  const {
    marketingCard: { image, copy, link, textColor },
  } = data;
  const portraitWithFallback: ImageWithAltText =
    image.portrait ?? image.landscape;
  const landscapeWithFallback: ImageWithAltText =
    image.landscape ?? image.portrait;

  if (!(image.portrait?.source || image.landscape?.source)) {
    throw Error(
      `Marketing card with copy: ${copy} is missing an image source.`,
    );
  }

  return {
    copy: toHTML(copy[lang]),
    image: {
      portrait: toImageProps(portraitWithFallback, lang),
      landscape: toImageProps(landscapeWithFallback, lang),
    },
    link: toLinkProps(link, region, lang),
    textColor,
    isPortrait: data.columnSpan === 1,
    rowPlacement: data.rowPlacement,
  };
}
